<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/procedure">
        <template #content>
            <div class="p-fluid painel painel-2 card">
                <div class="font-medium text-xl text-900 mb-3">
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="exames-form" />
                        <h3 class="ml-1">{{ title }}</h3>
                    </div>
                </div>
                <TabView :scrollable="true" ref="tabview" :activeIndex="active" @tab-click="onTabClick">
                    <TabPanel header="Geral" :disabled="false" :key="0">
                        <ProcedimentoCadastro :active="active" @desabilitarAbas="desabilitarAbas" @habilitarAbas="habilitarAbas" />
                    </TabPanel>

                    <TabPanel header="Tipo Exame/Periodicidade" :disabled="false" :key="1">
                        <SolicitaTipoExameOcupGrid
                            :queryParams="queryParamsTipoExame"
                            :service="serviceTipoExame"
                            :parentName="parentNameTipoExame"
                            :labelBotaoAdicionarItem="labelBotaoAdicionarItemTipoExame"
                            @setSolicitaTipoExameOcupRisco="setSolicitaTipoExameOcupRisco"
                            @voltarTabGeral="voltarTabGeral"
                        />

                        <Divider />
                        <h3>Períodicidade</h3>

                        <Periodicidades
                            :active="active"
                            origem="procedure"
                            @desabilitarAbas="desabilitarAbas"
                            @habilitarAbas="habilitarAbas"
                            @setPeriodicidadeRisco="setPeriodicidadeRisco"
                            :lastPage="false"
                        />
                    </TabPanel>

                    <TabPanel header="Riscos" :disabled="false" :key="2">
                        <RiscoProcedimentoGrid
                            :queryParams="queryParams"
                            :service="serviceRiscosProcedimentos"
                            :parentName="parentName"
                            :labelBotaoAdicionarItem="labelBotaoAdicionarItem"
                            :showButtonAlterar="true"
                            :defaultEdit="false"
                            @onEditGrid="onEditGrid"
                            @voltarTabGeral="voltarTabGeral"
                        />
                    </TabPanel>
                </TabView>
            </div>
        </template>
    </AppPanel>
</template>

<script>
import { getCurrentCompany } from '@/services/store';
import Periodicidades from '../../components/periodicidade/PeriodicidadeCadastro.vue';
import ProcedimentoCadastro from './components/ProcedimentoCadastro.vue';
import RiscoProcedimentoGrid from './components/RiscoProcedimentoGrid.vue';
import SolicitaTipoExameOcupGrid from '../../components/solicita-tipo-exame-ocup/SolicitaTipoExameOcupGrid.vue';
import NestedService from '@/services/NestedService';
import AppInfoManual from '../../components/AppInfoManual.vue';

export default {
    components: {
        Periodicidades,
        SolicitaTipoExameOcupGrid,
        ProcedimentoCadastro,
        RiscoProcedimentoGrid,
        AppInfoManual
    },
    data() {
        return {
            active: 0,
            serviceRiscosProcedimentos: null,
            serviceTipoExame: null,
            parentName: 'risco-procedimento',
            parentNameTipoExame: 'procedure',
            labelBotaoAdicionarItem: 'Risco',
            labelBotaoAdicionarItemTipoExame: 'Adicionar Tipo de Exame',
            solicitaTipoExameOcup: null,
            periodicidade: null
        };
    },
    created() {
        this.serviceRiscosProcedimentos = new NestedService('/risco-procedimento');
        this.serviceTipoExame = new NestedService('/solicita-tipo-exame-ocup');
    },
    mounted() {
        if (!this.$route.params.id) this.desabilitarAbas();
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar procedimento' : 'Adicionar procedimento';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        },
        queryParams() {
            return {
                procedimentoId: this.$route.params.id,
                solicitaTipoExameOcup: this.solicitaTipoExameOcup,
                periodicidade: this.periodicidade
            };
        },
        queryParamsTipoExame() {
            return {
                origem: 'procedure',
                idOrigem: this.$route.params.id
            };
        }
    },
    watch: {
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                alert('Não é possível trocar a empresa no fluxo de criação/edição de um cadastro!');
                this.$router.push({ path: 'list' });
            }
        }
    },
    methods: {
        setPeriodicidadeRisco(data) {
            this.periodicidade = data;
        },
        setSolicitaTipoExameOcupRisco(data) {
            this.solicitaTipoExameOcup = data;
        },
        onTabClick(event) {
            this.active = event.index;
        },
        habilitarAbas() {
            this.$refs.tabview.tabs.filter((item) => item.key != this.active).forEach((item) => (item.props.disabled = false));
            this.$refs.tabview.$forceUpdate();
        },
        desabilitarAbas() {
            this.$refs.tabview.tabs.filter((item) => item.key != this.active).forEach((item) => (item.props.disabled = true));
            this.$refs.tabview.$forceUpdate();
        },
        onEditGrid(data) {
            this.$router.push(`/risco-procedimento/${data.id}`);
        },
        voltarTabGeral() {
            this.active = 0;
        }
    }
};
</script>