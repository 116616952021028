const ProcedureDiagnosticoEnum = Object.freeze({
    'PD0583' : '0583',
    'PD0998' : '0998',
    'PD0999' : '0999',
    'PD1128' : '1128',
    'PD1230' : '1230',
    'PD1992' : '1992',
    'PD1993' : '1993',
    'PD1994' : '1994',
    'PD1995' : '1995',
    'PD1996' : '1996',
    'PD1997' : '1997',
    'PD1998' : '1998',
    'PD1999' : '1999',
    'PD9999' : '9999'
})

export default ProcedureDiagnosticoEnum;
