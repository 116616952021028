<template>
    <AppFormCadastroNested ref="cadProcedure" :service="service" :form="form" :disableSave="isInativo"
        backPath="/procedure" @onBeforeSave="onBeforeSave" @onAfterSave="onAfterSave" @onLoadDataEdit="onLoadDataEdit"
        @onValidate="onValidate" @onHabilitarEdicao="habilitarEdicao" @onCancelarEdicao="cancelarEdicao">
        <template #content>
            <div class="grid">
                <div class="field col-12">
                    <label>Nome do exame</label>
                    <InputText id="name" v-model.trim="form.name" maxlength="150" autocomplete="off"
                        :class="{ 'p-invalid': submitted && !form.name }" :disabled="isInativo" />
                    <small class="p-error" v-if="submitted && !form.name">Nome do procedimento é obrigatório.</small>
                </div>

                <div class="field col-4">
                    <label class="m-2 ml-0">Recomendação</label>
                    <Textarea v-model.trim="form.recommendation" autocomplete="off" :autoResize="true" rows="3"
                        :disabled="isInativo" />
                </div>

                <div class="field col-4">
                    <label class="m-2 ml-0">Conduta profissional</label>
                    <Textarea id="name" v-model.trim="form.condutaProfissional" autocomplete="off" :autoResize="true"
                        rows="3" :disabled="isInativo" />
                </div>

                <div class="field col-4">
                    <label class="m-2 ml-0">Observações do agendamento</label>
                    <Textarea id="name" v-model.trim="form.observacaoAgendamentoConsulta" autocomplete="off"
                        :autoResize="true" rows="3" :disabled="isInativo" />
                </div>

                <div class="field col-3">
                    <label class="m-2 ml-0">Sexo</label>
                    <Dropdown v-model="form.sexo" :options="sexos" optionValue="valor" optionLabel="descricao"
                        placeholder="Selecione..." :class="{ 'p-invalid': submitted && !form.sexo }"
                        :disabled="isInativo" />
                    <small class="p-error" v-if="submitted && !form.sexo"> Obrigatório informar o sexo. </small>
                </div>

                <div class="field col-3">
                    <label for="duration">Duração</label>
                    <InputMask id="duration" v-model="form.duration" mask="99:99"
                        :class="{ 'p-invalid': submitted && !form.duration }" :disabled="isInativo" />
                    <small class="p-error" v-if="submitted && !form.duration">
                        {{ constsEnum.campoObrigatorio }}
                    </small>
                </div>

                <div class="field col-3">
                    <label for="diasAntecedencia">Dias de antecedência em relação ao exame clínico</label>
                    <InputNumber inputId="diasAntecedencia" :min="0" v-model="form.diasAntecedencia"
                        :class="{ 'p-invalid': submitted && form.diasAntecedencia == null }" :disabled="isInativo" />
                    <small class="p-error" v-if="submitted && form.diasAntecedencia == null">
                        {{ constsEnum.campoObrigatorio }}
                    </small>
                </div>

                <div class="field col-3">
                    <label for="clinical">É exame clínico?</label>
                    <SelectButton id="clinical" v-model="form.clinical" :options="[
        { label: 'Sim', value: true },
        { label: 'Não', value: false }
    ]" optionLabel="label" optionValue="value" :class="{ 'p-invalid': submitted && form.clinical == null }"
                        :disabled="isInativo" />
                    <small class="p-error" v-if="submitted && form.clinical == null">
                        {{ constsEnum.campoObrigatorio }}
                    </small>
                </div>

                <div class="field col-3">
                    <label for="clinical">Avaliação Clínica Ocupacional?</label>
                    <SelectButton id="clinical" v-model="form.clinicoObrigatorio" :options="[
        { label: 'Sim', value: true },
        { label: 'Não', value: false }
    ]" optionLabel="label" optionValue="value" :class="{ 'p-invalid': submitted && form.clinicoObrigatorio == null }"
                        :disabled="isInativo" />
                    <small class="p-error" v-if="submitted && form.clinicoObrigatorio == null">
                        {{ constsEnum.campoObrigatorio }}
                    </small>
                </div>

                <div class="field col-3">
                    <label for="clinical">É por hora marcada?</label>
                    <SelectButton id="clinical" v-model="form.horaMarcada" :options="[
        { label: 'Sim', value: true },
        { label: 'Não', value: false }
    ]" optionLabel="label" optionValue="value" :class="{ 'p-invalid': submitted && form.horaMarcada == null }"
                        :disabled="isInativo" />
                    <small class="p-error" v-if="submitted && form.horaMarcada == null">
                        {{ constsEnum.campoObrigatorio }}
                    </small>
                </div>

                <div class="field col-3">
                    <label for="ocupacional">É exame ocupacional?</label>
                    <SelectButton id="ocupacional" v-model="form.ocupacional" :options="[
        { label: 'Sim', value: true },
        { label: 'Não', value: false }
    ]" optionLabel="label" optionValue="value" :class="{ 'p-invalid': submitted && form.ocupacional == null }"
                        :disabled="isInativo" />
                    <small class="p-error" v-if="submitted && form.ocupacional == null">
                        {{ constsEnum.campoObrigatorio }}
                    </small>
                </div>

                <div class="field col-3">
                    <label for="ocupacional">Permite agendamento automático?</label>
                    <SelectButton id="ocupacional" v-model="form.agendamentoAutomatico" :options="[
        { label: 'Sim', value: true },
        { label: 'Não', value: false }
    ]" optionLabel="label" optionValue="value"
                        :class="{ 'p-invalid': submitted && form.agendamentoAutomatico == null }"
                        :disabled="isInativo" />
                    <small class="p-error" v-if="submitted && form.agendamentoAutomatico == null">
                        {{ constsEnum.campoObrigatorio }}
                    </small>
                </div>

                <div class="field col-4">
                    <label for="code">Código SOC</label>
                    <InputText id="code" v-model.trim="form.code" autofocus autocomplete="off"
                        :class="{ 'p-invalid': submitted && !form.code }" :disabled="isInativo" />
                    <small class="p-error" v-if="submitted && !form.code">
                        {{ constsEnum.campoObrigatorio }}
                    </small>
                </div>

                <div class="field col-4">
                    <label for="rh_code">Código Externo</label>
                    <InputText id="rh_code" v-model.trim="form.rhCode" autofocus autocomplete="off"
                        :class="{ 'p-invalid': submitted && !form.rhCode }" :disabled="isInativo" />
                    <small class="p-error" v-if="submitted && !form.rhCode">
                        {{ constsEnum.campoObrigatorio }}
                    </small>
                </div>

                <div class="field col-4">
                    <label for="clinical">
                        É exame PCD?
                        <span
                            v-tooltip="'Quando habilitado, será considerado o tempo configurado das telas: Parâmetros Gerais e Cadastro de Agenda.'"
                            class="text-primary pi pi-info-circle mx-1" /></label>
                    <SelectButton id="pcd" v-model="form.pcd" :options="[
        { label: 'Sim', value: true },
        { label: 'Não', value: false }
    ]" optionLabel="label" optionValue="value" :class="{ 'p-invalid': submitted && typeof form.pcd !== 'boolean' }"
                        :disabled="isInativo" />
                    <small class="p-error" v-if="submitted && typeof form.pcd !== 'boolean'">
                        {{ constsEnum.campoObrigatorio }}
                    </small>
                </div>

                <div class="field col-6">
                    <label for="procedimentosReservaHorario" class="m-2 ml-0">Procedimento Padrão</label>
                    <DropdowneProcedimentoPadrao id="procedimentosReservaHorario"
                        v-model="form.procedimentosReservaHorario"
                        :class="{ 'p-invalid': submitted && !form.procedimentosReservaHorario }"
                        :disabled="isInativo" />
                </div>

                <div class="col-12">
                    <h4>eSocial</h4>
                </div>
                <div class="field field-checkbox col-12">
                    <label class="m-2 ml-0">Envia e-Social</label>
                    <InputSwitch v-model="form.enviaEsocial" :disabled="isInativo" />
                </div>

                <div class="field col-6">
                    <label for="esocialProcedimento" class="m-2 ml-0">Procedimento Diagnóstico</label>
                    <DropdowneSocialProcedimentos id="esocialProcedimento" v-model="form.esocialProcedimento"
                        :class="{ 'p-invalid': submitted && !form.esocialProcedimento && form.enviaEsocial }"
                        :disabled="isInativo" />
                    <small class="p-error" v-if="submitted && !form.esocialProcedimento && form.enviaEsocial">Nome do
                        exame é obrigatório.</small>
                </div>

                <div class="field col-6">
                    <label class="m-2 ml-0">Observação sobre o procedimento diagnóstico realizado</label>
                    <Textarea :autoResize="true" rows="1" v-model.trim="form.observacaoDiagnosticoProcedimento"
                        maxlength="999" autocomplete="off" :class="{
        'p-invalid':
            submitted &&
            form.esocialProcedimento &&
            this.validaProcedimentosDiagnostico() &&
            !form.observacaoDiagnosticoProcedimento
    }" :disabled="isInativo" />
                    <small class="p-error" v-if="submitted && form.esocialProcedimento && this.validaProcedimentosDiagnostico() && !form.observacaoDiagnosticoProcedimento
        ">
                        Observação é obrigatório.
                    </small>
                </div>
                <Divider />
                <div class="col-6 flex align-content-center">
                    <InputSwitch class="mr-2" v-model="form.ativo" :disabled="isInativo" />
                    <label>Ativo</label>
                </div>
            </div>
        </template>
    </AppFormCadastroNested>
</template>

<script>
import BaseService from '../../../services/BaseService';
import ProcedureDiagnosticoEnum from '../../../enums/ProcedimentoDiagnosticoEnum';
import ConstsEnum from '../../../enums/ConstsEnum';
import DropdowneSocialProcedimentos from './DropdowneSocialProcedimentos.vue';
import DropdowneProcedimentoPadrao from './DropdowneProcedimentoPadrao.vue';
export default {
    props: {
        active: Number
    },
    components: {
        DropdowneSocialProcedimentos,
        DropdowneProcedimentoPadrao
    },
    emits: ['desabilitarAbas', 'habilitarAbas'],
    data() {
        return {
            form: {
                ativo: true,
                horaMarcada: undefined,
                ocupacional: undefined,
                agendamentoAutomatico: undefined
            },
            isInativo: false,
            submitted: false,
            optionExameClinico: [
                { label: 'Sim', value: true },
                { label: 'Não', value: false }
            ],
            service: null,
            sexos: [
                {
                    valor: 'M',
                    descricao: 'Masculino'
                },
                {
                    valor: 'F',
                    descricao: 'Feminino'
                },
                {
                    valor: 'A',
                    descricao: 'Ambos'
                }
            ],
            procedimentosDiagnostico: [
                ProcedureDiagnosticoEnum.PD0583,
                ProcedureDiagnosticoEnum.PD0998,
                ProcedureDiagnosticoEnum.PD0999,
                ProcedureDiagnosticoEnum.PD1128,
                ProcedureDiagnosticoEnum.PD1230,
                ProcedureDiagnosticoEnum.PD1992,
                ProcedureDiagnosticoEnum.PD1993,
                ProcedureDiagnosticoEnum.PD1994,
                ProcedureDiagnosticoEnum.PD1995,
                ProcedureDiagnosticoEnum.PD1996,
                ProcedureDiagnosticoEnum.PD1997,
                ProcedureDiagnosticoEnum.PD1998,
                ProcedureDiagnosticoEnum.PD1999,
                ProcedureDiagnosticoEnum.PD9999
            ]
        };
    },
    computed: {
        constsEnum() {
            return ConstsEnum;
        }
    },
    created() {
        this.service = new BaseService('/procedures');
    },
    mounted() {
        this.isInativo = !!this.$route.params.id;
        this.form.ativo = true;
    },
    methods: {
        validaProcedimentosDiagnostico() {
            if (this.form.esocialProcedimento) {
                return this.procedimentosDiagnostico.find((value) => value == this.form.esocialProcedimento.codigo);
            }
            return true;
        },
        async onLoadDataEdit(data) {
            this.form = data;
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                this.$toast.add({ severity: 'error', summary: 'Há campos obrigatórios não preenchidos', life: 3000 });
                return cbError();
            }

            return cbSuccess();
        },
        hasError() {
            if (!this.form.name) return true;
            if (!this.form.esocialProcedimento && this.form.enviaEsocial) return true;
            if (this.form.esocialProcedimento && this.validaProcedimentosDiagnostico() && !this.form.observacaoDiagnosticoProcedimento) return true;
            if (!this.form.sexo) return true;
            if (this.form.clinical == null) return true;
            if (this.form.clinicoObrigatorio == null) return true;
            if (typeof this.form.horaMarcada !== 'boolean') return true;
            if (this.form.diasAntecedencia == null) return true;
            if (typeof this.form.ocupacional !== 'boolean') return true;
            if (typeof this.form.agendamentoAutomatico !== 'boolean') return true;
            if (!this.form.duration) return true;
            if (!this.form.code) return true;
            if (!this.form.rhCode) return true;
        },
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave() {
            this.isInativo = true;
            this.submitted = false;
            this.$emit('habilitarAbas');
        },
        habilitarEdicao() {
            this.$emit('desabilitarAbas');
            this.isInativo = false;
        },
        cancelarEdicao(record) {
            this.isInativo = true;
            this.form = JSON.parse(JSON.stringify(record));
            this.$emit('habilitarAbas');
        }
    }
};
</script>
