<template>
    <AppOnePageDropdown v-model="value" :idValue="idValue" optionLabel="descricao"
        placeholder="Selecione o procedimento..." :service="$service" :itemSize="itemSize">
    </AppOnePageDropdown>
</template>
<script>
import BaseService from '../../../services/BaseService';
export default {
    props: {
        modelValue: {
            type: Object
        },
        itemSize: {
            type: Number,
            default: 100
        },
        idValue: {
            type: Number
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            $service: null
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    async created() {
        this.$service = new BaseService('/procedimentos-reserva-horario');
    }
};
</script>
